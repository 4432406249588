@import "../src/app/styles/color-definition.scss";
@import "../src/app/styles/margin-definition.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  background: $black-background-1;
}
