@font-face {
    font-family: "visbyCF";
    src: url("../../assets/fonts/VisbyCF-Light.otf") format("truetype");
}

@font-face {
    font-family: "openSans";
    src: url("../../assets/fonts/OpenSans.ttf") format("opentype");
}

@font-face {
    font-family: "Shadows Into Light";
    src: url("../../assets/fonts/ShadowsIntoLight-Regular.ttf") format("opentype");
}