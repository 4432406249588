.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-50 {
  margin-right: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-n-65 {
  margin-right: -65px;
}

.mr-n-135 {
  margin-right: -135px;
}

.ml-238 {
  margin-left: 238px;
}

.ml-0 {
  margin-left: 0px;
}